import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'components/Layout'
import LocalImage from 'components/LocalImage'

import imageHeader from 'static/images/reviews/header/header_picture.webp'
import imageHeaderButton from 'static/images/reviews/header/button.webp'
import imageHeaderReviewSmall from 'static/images/reviews/header/review_small.webp'
import imageHeaderReviewBig from 'static/images/reviews/header/review_big.webp'

const StyledImageHeader = styled(LocalImage)`
  position: absolute;
`

const StyledImageHeaderButton = styled(StyledImageHeader)`
  box-shadow: 0px 10px 30px rgba(1, 90, 255, 0.25);
  border-radius: 6px;
  cursor: pointer;

  ${({ coefficient }) => `  
  right: ${coefficient * 40}px;
  top: ${coefficient * 245}px;
  width: ${coefficient * 108}px;
  height: ${coefficient * 47}px`};
  animation: reviews-image-header-button 4s linear infinite;
  @keyframes reviews-image-header-button {
    0%,
    3% {
      transform: rotateZ(0);
    }
    8% {
      transform: rotateZ(-15deg);
    }
    10% {
      transform: rotateZ(10deg);
    }
    12% {
      transform: rotateZ(-10deg);
    }
    15% {
      transform: rotateZ(6deg);
    }
    18% {
      transform: rotateZ(-4deg);
    }
    20%,
    100% {
      transform: rotateZ(0);
    }
  }
`

const StyledImageHeaderReviewSmall = styled(StyledImageHeader)`
  filter: drop-shadow(14px 20px 36px rgba(26, 51, 100, 0.07));
  backdrop-filter: blur(22px);
  border-radius: 16px;

  ${({ coefficient }) => `  
  bottom: ${coefficient * 45}px;
  left: ${coefficient * 40}px;
  width: ${coefficient * 375}px;
  height: ${coefficient * 150}px`};
  animation: reviews-image-header-review-small 8s linear 1s infinite both;
  @keyframes reviews-image-header-review-small {
    0% {
      transform: translateX(0) translateY(0);
    }
    16.6666666667% {
      transform: translateX(24px);
    }
    33.3333333333% {
      transform: translateX(48px);
    }
    50.0000000001% {
      transform: translateX(24px);
    }
    66.6666666668% {
      transform: translateX(16px);
    }
    83.3333333335% {
      transform: translateX(16px);
    }
    100% {
      transform: translateX(0);
    }
  }
`

const StyledImageHeaderReviewBig = styled(StyledImageHeader)`
  filter: drop-shadow(-3px 13px 28px rgba(26, 51, 100, 0.1));
  backdrop-filter: blur(22px);

  ${({ coefficient }) => `  
  right: ${coefficient * -45}px;
  top: ${coefficient * 1}px;
  width: ${coefficient * 262.17}px;
  height: ${coefficient * 150}px`};

  animation: reviews-image-header-review-big 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes reviews-image-header-review-big {
    0% {
      transform: scale(1) translateX(0) translateY(0);
    }
    16.6666666667% {
      transform: scale(1.12) translateY(12px);
    }
    33.3333333333% {
      transform: scale(1.12) translateY(12px);
    }
    50.0000000001% {
      transform: scale(1.12) translateY(24px);
    }
    66.6666666668% {
      transform: scale(1.12) translateY(24px);
    }
    83.3333333335% {
      transform: scale(1) translateY(0);
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }
`

const HeaderImageAnimation = () => {
  // this is a coefficient of the image. Real size 500px, but on the site it is 400px, so the coefficient would be 400/500
  const [coefficient, setCoefficient] = useState(1)
  const imageWrapperRef = useRef(null)

  const countCoefficient = () => {
    if (imageWrapperRef && imageWrapperRef.current) {
      const { current: { clientWidth, firstChild: { naturalWidth } = {} } = {} } = imageWrapperRef
      const newCoefficient = (clientWidth * 2) / naturalWidth
      if (!Number.isNaN(newCoefficient) && newCoefficient > 0) {
        setCoefficient(newCoefficient)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', countCoefficient)

    return () => {
      window.removeEventListener('resize', countCoefficient)
    }
  }, [])

  return (
    <Flex width="100%">
      <Flex maxWidth="626px" mx="auto" pb={{ mobile: 'l', desktop: 0 }}>
        <Box width="100%" position="relative" ref={imageWrapperRef}>
          <LocalImage
            src={imageHeader}
            width="100%"
            mx="auto"
            alt="Strengthen your online reputation with review management"
            onLoad={() => {
              countCoefficient()
            }}
          />

          <StyledImageHeaderButton
            src={imageHeaderButton}
            coefficient={coefficient}
            onClick={() => {
              window.open('/pricing', '_self')
            }}
          />
          <StyledImageHeaderReviewSmall src={imageHeaderReviewSmall} coefficient={coefficient} />
          <StyledImageHeaderReviewBig src={imageHeaderReviewBig} coefficient={coefficient} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default HeaderImageAnimation
