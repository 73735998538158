import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, space } from 'theme'
import { Grid, Flex } from 'components/Layout'
import LocalImage from 'components/LocalImage'
import { Text } from 'components/Typography'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import logo from 'static/logo/logo.png'
import imagePerson1 from 'static/images/people/faq_person_1.webp'
import imagePerson2 from 'static/images/people/faq_person_2.webp'

const StyledQuestionWrapper = styled(Flex)`
  padding: ${space.m};
  background: ${COLOR_CONSTANTS.SALT};
  border-radius: 0px 26px 26px 26px;
`

const StyledAnswerWrapper = styled(Flex)`
  padding: ${space.m};
  border: 1px solid #b6cef0;
  border-radius: 26px 0px 26px 26px;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const DEFAULT_FAQ = [
  {
    question: {
      image: imagePerson1,
      text: 'Can I schedule Instagram and Facebook Reels?',
    },
    answer: {
      image: logo,
      text:
        'Yes, you can schedule Instagram and Facebook Reels! Vista Social will take care of all of your video imperfections to ensure that your reels are published.',
      link: '',
    },
  },
  {
    question: {
      image: imagePerson2,
      text: 'Can I schedule TikTok videos?',
    },
    answer: {
      image: logo,
      text:
        'Yes, Vista Social has full support for TikTok video scheduling. You can also manage all of your TikTok comments using Vista Social!',
      link:
        'https://support.vistasocial.com/hc/en-us/articles/4409604728219-Changing-user-permissions-in-your-organization',
    },
  },
]

const QuestionAnswerComponent = ({ faqs }) => {
  return (
    <Grid
      width="100%"
      maxWidth="920px"
      mx="auto"
      gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, 1fr)' }}
      gridGap="l"
    >
      {faqs.map(
        (
          {
            question: { image: questionImage, text: questionText } = {},
            answer: { image: answerImage, text: answerText, link } = {},
          },
          index
        ) => (
          <Flex key={index} flexDirection="column" maxWidth="445px" width="100%" mx={{ mobile: 'auto', tablet: 0 }}>
            <Flex>
              <LocalImage src={questionImage} width="48px" height="48px" />
              <StyledQuestionWrapper mt="l" ml="s">
                <Flex maxWidth="310px" px="s">
                  <Text fontSize="l" fontWeight="bold" color="primary" textAlign="left">
                    {questionText}
                  </Text>
                </Flex>
              </StyledQuestionWrapper>
            </Flex>
            <Flex mt="m">
              <StyledAnswerWrapper mt="l" mr="s">
                <Flex maxWidth="310px" px="s" flexDirection="column">
                  <Text fontSize="m" color="secondaryText" textAlign="left">
                    {answerText}
                  </Text>
                  {link && (
                    <StyledLinkText mt="m" as="a" href={link} textAlign="left">
                      <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                        LEARN MORE
                      </Text>
                      <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
                    </StyledLinkText>
                  )}
                </Flex>
              </StyledAnswerWrapper>
              <LocalImage src={answerImage} width="48px" height="48px" />
            </Flex>
          </Flex>
        )
      )}
    </Grid>
  )
}

QuestionAnswerComponent.defaultProps = {
  faqs: DEFAULT_FAQ,
}

QuestionAnswerComponent.propTypes = {
  faqs: PropTypes.array,
}

export default QuestionAnswerComponent
